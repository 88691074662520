<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card class="">
      <v-card-title>
        General
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useUtils } from '@/@core/libs/i18n'

export default {
  props: {},
  setup() {
    const { t } = useUtils()

    return {
      t,
    }
  },
}
</script>
