<template>
  <div id="platform-settings">
    <v-card>
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          :append="tab.append"
          :to="tab.to"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.title"
          :value="tab.value"
        >
          <component :is="tab.child"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiImport } from '@mdi/js'
import { useUtils } from '@/@core/libs/i18n'
import { ref } from '@vue/composition-api'
import SettingsTabGeneral from './settings-tab-general/SettingsTabGeneral.vue'
import SettingsTabImport from './settings-tab-import/SettingsTabImport.vue'

export default {
  components: {
    SettingsTabGeneral,
    SettingsTabImport,
  },

  setup() {
    const { t } = useUtils()

    const tab = ref(null)

    const tabs = [
      // { title: t('General'), icon: mdiCog, child: 'SettingsTabGeneral' },
      {
        title: t('Import'),
        icon: mdiImport,
        child: 'SettingsTabImport',
        append: true,
        to: '#import',
        value: 'import',
      },
    ]

    return {
      t,

      tab,
      tabs,
    }
  },
}
</script>

<style lang="scss">
</style>
