<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card class="">
      <v-card-title>
        {{ t('Fetch Data from Microsoft Business Central API') }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text v-if="scripts && !scripts.loading">
        <v-row>
          <v-col cols="6">
            <import-card
              :outlined="true"
              :executing="executing"
              :script="importAllScript"
              :title="`Import ${importAllScript.entity}`"
              :expanded="expandedCard === importAllScript.entity"
              @toggle="entity => expandedCard = entity"
              @execute="() => runScript(importAllScript.entity)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider v-if="scripts && !scripts.loading"></v-divider>

      <v-card-text>
        <v-row v-if="scripts && !scripts.loading">
          <v-col
            v-for="script in filteredScripts"
            :key="script.entity"
            cols="3"
          >
            <import-card
              :script="script"
              :executing="executing"
              :expanded="expandedCard === script.entity"
              @execute="() => runScript(script.entity)"
              @toggle="entity => expandedCard = entity"
            />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col align="center">
            <v-progress-circular
              size="47"
              indeterminate
              color="primary"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { mdiAlert, mdiDatabaseAlertOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onUnmounted } from '@vue/composition-api'

export default {
  setup() {
    const { t } = useUtils()
    const vm = getCurrentInstance().proxy

    const expandedCard = ref(null)

    const loading = computed({
      get: () => vm.$store.getters['settings/loading'],
    })

    const executing = computed({
      get: () => vm.$store.getters['settings/executing'],
    })

    const scripts = computed({
      get: () => vm.$store.getters['settings/scripts'],
    })

    const filteredScripts = computed(
      () => Object.fromEntries(Object.entries(scripts.value).filter(({ 0: entity }) => entity !== 'All')),
      // eslint-disable-next-line function-paren-newline
    )

    const importAllScript = computed(() => scripts.value?.All)

    const healthCheck = () => vm.$store.dispatch('settings/healthCheck')
    const fetchScripts = () => vm.$store.dispatch('settings/fetchScripts')
    const runScript = entity => vm.$store.dispatch('settings/runScript', entity)

    fetchScripts()

    const healthCheckInterval = ref(setInterval(() => healthCheck(), 5000))

    onUnmounted(() => {
      clearInterval(healthCheckInterval.value)
    })

    return {
      t,

      icons: {
        mdiAlert,
        mdiDatabaseAlertOutline,
      },

      loading,
      scripts,
      executing,

      filteredScripts,
      importAllScript,

      expandedCard,

      runScript,
    }
  },
}
</script>
